<template>
    <div class="filer-member-box">
        <div class="container">
            <!-- 筛选条件 -->
            <div class="filter-member-area-box">
                <span>省市区：</span>
                <!-- <area-cascader 
                    v-model="city"
                    type='text'
                    :level='1'
                    :data="$pcaa"
                    placeholder="请选择地区"
                    @change="handleArea"
                    class="filter-member-area-cascader">
                </area-cascader> -->
                <el-cascader
                    :options="options"
                    :props="{
                        value: 'label'
                    }"
                    v-model="city"
                    @change="handleArea"
                    size="small"
                    class="filter-member-cascader">
                </el-cascader>
            </div>
            <!-- 主题 -->
            <!-- <div class="filter-member-sift-box">
                <ul class="filter-member-sift-theme clearfix">
                    <li>
                        <a href="javascript:;"
                            @click="handleTheme('0')" 
                            :class="{'active-sift': active_style == '0'}">
                            全部
                        </a>
                    </li>
                    <li v-for="item in filerStyle"
                        :key="item.id">
                        <a href="javascript:;"
                            @click="handleTheme(item.id)" 
                            :class="{'active-sift': active_style == item.id}">
                            {{item.name}}
                        </a>
                    </li>
                </ul>
            </div> -->
            <!-- 排序规则 -->
            <ul class="filter-member-sort-box clearfix">
                <li v-for="item in filerSort"
                    :key="item.id">
                    <a href="javascript:;"
                        @click="handleSort(item.id)"
                        :class="{'active-sort': active_sort == item.id}">
                        {{item.name}}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { regionDataPlus } from "element-china-area-data";

export default {
    data() {
        let active_style = sessionStorage.getItem("active_style") || "0",
        active_sort = sessionStorage.getItem("active_sort") || "1",
        sift_area = sessionStorage.getItem("sift_area") || "",
        city = sift_area? sift_area.split("/") : [];
        return {
            options: regionDataPlus,
            active_style,
            active_sort,
            sift_area,
            city,
        }
    },
    props: {
        filerStyle: Array,
        filerSort: Array,
        remoteMethods: Function
    },
    methods: {
        handleArea(arg){
            let val = arg.join("/");
            this.sift_area = val;
            sessionStorage.setItem("sift_area", val);
            this.remoteMethods({
                area:  val,
                style: this.active_style,
                filter: this.active_sort
            })
        },
        handleTheme(arg){
            this.active_style = arg;
            sessionStorage.setItem("active_style", arg);
            this.remoteMethods({
                area: this.sift_area,
                style: arg,
                filter: this.active_sort
            })
        },
        handleSort(arg){
            this.active_sort = arg;
            sessionStorage.setItem("active_sort", arg);
            this.remoteMethods({
                area: this.sift_area,
                style: this.active_style,
                filter: arg
            })
        }
    },
}
</script>

<style scoped>
.filer-member-box{
    background-color: #fff;
}
/* 筛选条件 */
.filter-member-area-box{
    padding: 30px 0;
}
.filter-member-area-box>span{
    display: inline-block;
    vertical-align: middle;
    padding: 0 10px;
}
.filter-member-cascader{
    width: 300px;
}
.filter-member-cascader >>> input{
    border-radius: 15px;
}
/* .filter-member-area-cascader{
    display: inline-block;
    vertical-align: middle;
}
.filter-member-area-cascader>>>.area-select{
    width: 300px;
    border-radius: 15px;
}
.filter-member-area-cascader>>>.area-selected-trigger{
    padding: 6px 20px 6px 12px;
} */

.filter-member-sift-box{
    padding: 30px 0;
}
.filter-member-sift-theme>li,
.filter-member-sift-subtheme>li{
    float: left;
}
.filter-member-sift-theme>li+li,
.filter-member-sift-subtheme>li+li{
    margin-left: 10px;
}
.filter-member-sift-theme>li>a,
.filter-member-sift-subtheme>li>a{
    display: block;
    padding: 5px 20px;
    border-radius: 20px;
    font-size: 15px;
    color: #303133;
}
.filter-member-sift-theme>li>a.active-sift,
.filter-member-sift-subtheme>li>a.active-sift{
    color: #FFFFFF;
    background-color: #E6A23C;
}
.filter-member-sift-subtheme{
    margin-top: 10px;
}
/* 排序规则 */
.filter-member-sort-box{
    display: flex;
    justify-content: center;
    align-items: center;
}
.filter-member-sort-box>li+li{
    margin-left: 20px;
}
.filter-member-sort-box>li>a{
    display: block;
    padding: 10px 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 20px;
    color: #303133;
}
.filter-member-sort-box>li>a.active-sort{
    color: #FFFFFF;
    background-color: #8d9afc;
}
</style>